@tailwind base;
@tailwind components;
@tailwind utilities;


h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

* {
  box-sizing: border-box;
}
html,
body {
  overflow: hidden;
}
html {
  background-image: linear-gradient(to top, #d13424, #0e1015);
  height: 100%;
  width: 100%;
  font-family: "Inter", sans-serif;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
}

.scroll-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.bg-hero-pattern {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
